<template>
    <section id="aboutclients">
        <div class="aboutclients-content">
            <h1>Our Clients</h1>
            <div class="clients">
                <div class="client-box">
                    <a href="https://vanillavillage.mu/" target="_blank" rel="noopener noreferrer">
                        <img src="../../assets/media/besecure-client1.jpg" alt="Client 1">
                    </a>
                </div>
                <div class="client-box">
                    <a href="https://www.trimetys.com/" target="_blank" rel="noopener noreferrer">
                        <img src="../../assets/media/besecure-client2.jpg" alt="Client 2">
                    </a>
                </div>
                <div class="client-box">
                    <a href="https://feelfree.mu/en/" target="_blank" rel="noopener noreferrer">
                        <img src="../../assets/media/besecure-client3.png" alt="Client 3">
                    </a>
                </div>
                <div class="client-box jardins-danna">
                    <a href="https://www.annavillage.com/home" target="_blank" rel="noopener noreferrer">
                        <img src="../../assets/media/besecure-partner-jardinsdanna.svg" alt="Client 4">
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutClients'
}
</script>
<style scoped>
#aboutclients {
    background-color: #fff;
    padding: 5rem 0;
}

.aboutclients-content {
    max-width: 1050px;
    margin: auto;
}

.aboutclients-content h1 {
    text-align: center;
    color: var(--bs-grey);
    font-size: 2.5rem;
    margin-bottom: 4rem;
}

.clients {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.client-box {
    flex: 0 1 30%;
    display: flex;
    justify-content: center;
}

.client-box a {
    display: flex;
    justify-content: center;
    width: 100%;
}

.client-box img {
    width: 60%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.client-box img:hover {
    transform: scale(1.05);
    cursor: pointer;
}

/* Large Tablets */
@media screen and (max-width: 1024px) {
    .aboutclients-content {
        max-width: 90%;
    }

    .client-box img {
        width: 80%;
    }

    .jardins-danna {
        margin-top: 3rem;
    }
}

/* Small Tablets */
@media screen and (max-width: 768px) {
    .aboutclients-content h1 {
        font-size: 2rem;
    }

    .clients {
        flex-direction: column;
        align-items: center;
    }

    .client-box {
        flex: 0 1 auto;
        width: 60%;
        margin-bottom: 2rem;
    }

    .client-box img {
        width: 100%;
    }


}

/* Large Phones */
@media screen and (max-width: 425px) {
    #aboutclients {
        padding: 5rem 0;
    }

    .aboutclients-content h1 {
        font-size: 1.8rem;
    }

    .client-box {
        width: 50%;
    }
}

/* Small Phones */
@media screen and (max-width: 375px) {
    .aboutclients-content h1 {
        font-size: 1.5rem;
    }

    .client-box {
        width: 90%;
    }
}
</style>